<template>
	<div class="grid layout-help-page">
		<div class="col-12">
			<div class="card p-0">
				<div class="grid grid-nogutter">
					<div class="col-12 layout-help-page-header">
						<img src="/img/map.jpg" alt="help-header" style="height: 250px; margin: 2rem; padding: 0rem; width:96.5%" />
						<h1 class="layout-help-page-header-text" style="padding: 1rem;">{{ getLabel("Basket") }}</h1>
						<div class="layout-help-page-search p-input-icon-left p-fluid shadow-2">
							<i class="pi pi-search"></i>
							<InputText type="text" :placeholder="getSearchLabel()" />
						</div>
					</div>

					<div class="col-12 pl-5 pr-5 pt-5">
						<div>
							<Button id="alladdbutton" label="Add All" icon="pi pi-shopping-bag" class="p-button-text fs-xlarge mr-3 ml-3" @click="itemsAdd"></Button>
							<Button id="alldelbutton" label="Delete All" icon="pi pi-trash" class="p-button-text fs-xlarge mr-3 ml-3" @click="itemsDelete"></Button>
						</div>
					</div>

					<div class="col-12 pr-5 pl-5 pb-5 pt-2">
						<div v-for="item in basket" :key="item">
							<Accordion>
								<AccordionTab>
									<template v-slot:header>
										<div class="flex align-items-center">
											<i class="pi pi-shopping-bag fs-large mr-2 yellow-color" @click="itemAdd(item)"></i>
											<i class="pi pi-trash fs-large mr-2 red-color" @click="itemDelete(item)"></i>
											<div class="flex flex-column">
												<h5 class="mb-0">{{ getSyn(item.concept,0) }}</h5>
												<p> {{ getAgglevel(item.agglevel, item.agglevelid) }} - {{ item.yearid }}</p>
											</div>
										</div>
									</template>

									<Accordion class="questions">
										<AccordionTab :header="getLabel('Synonyms')">
											{{ getSyn(item.concept,1) }}
										</AccordionTab>
										<AccordionTab :header="getLabel('Years')">
											{{ item.years }}
										</AccordionTab>
										<AccordionTab :header="getLabel('Levels')">
											{{ item.agglevel }}
										</AccordionTab>


									</Accordion>

								</AccordionTab>
							</Accordion>
						</div>
					</div>
				</div>

				<Dialog v-model:visible="deleteItemDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="deleteItem">Are you sure you want to delete <b> {{ getSyn(deleteItem.concept,0) }} - {{ getAgglevel(deleteItem.agglevel, deleteItem.agglevelid) }} - {{ deleteItem.yearid }}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteItemDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="basketDelete(deleteItem.productid)" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteItemsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span>Are you sure you want to delete all products?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteItemsDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="basketAllDelete()" />
					</template>
				</Dialog>

				<Dialog v-model:visible="addItemDialog" :style="{width: '450px'}" header="Order detail" :modal="true" class="p-fluid">
					<div class="field">
						<label for="ordername">Order name</label>
						<InputText id="ordername" v-model.trim="addItem.ordername" required="true" autofocus :class="{'p-invalid': submitted && !addItem.ordername}" />
						<small class="p-invalid" v-if="submitted && !addItem.ordername">Order name is required.</small>
					</div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="addItemDialog = false" />
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="orderAdd()" />
					</template>					
				</Dialog>				


				<Dialog v-model:visible="addItemsDialog" :style="{width: '450px'}" header="Order detail" :modal="true" class="p-fluid">
					<div class="field">
						<label for="ordername">Order name</label>
						<InputText id="ordername2" v-model.trim="ordername" required="true" autofocus :class="{'p-invalid': submitted && !ordername}" />
						<small class="p-invalid" v-if="submitted && !ordername">Order name is required.</small>
					</div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="addItemsDialog = false" />
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="orderAllAdd()" />
					</template>					
				</Dialog>				

			</div>
		</div>
	</div>

</template>

<script>

import BasketService from '../service/BasketService';
import UserService from '../service/UserService';
import {FilterMatchMode} from 'primevue/api';

export default {
	data() {
		return {
			text: null,
			filteredText: [],
			baskets: null,
			basket: [],
			deleteItemDialog: false,
			deleteItemsDialog: false,	
			deleteItem: null,
			addItemDialog: false,
			addItemsDialog: false,
			addItem: null,
			submitted: false,
			ordername: null,
		}
	},
	props: {
		items: Array,
	},
	userService: null,
	basketService: null,
	created() {
		this.basketService = new BasketService();
		this.userService = new UserService();
		if (! this.userService.checkUsersToken()) {
			let user = JSON.parse(localStorage.getItem('user'));
			if (user && user.token) {
				localStorage.removeItem('user');
			}
			this.$router.push("/login")
		}
	},
	mounted() {
		this.basketService.getBaskets().then(data => {
			if (data.code == 200) {
				this.basket = data.result;
			}
		});
	},	
	methods: {
		filterText(event) {
			const query = event.query;
			let filteredText = []

			for (let i = 0; i < 10; i++) {
				filteredText.push(query + i);
			}
			this.filteredText = filteredText;
		},
		getSyn (name, val) {
			if (name != null && name.length > 0) {
				var myArray = name.split("|");
				if (myArray.length > 1) {
					if (val == 0) {
						return myArray[0];
					} 
					var info = "";
					for (var i = 1; i < myArray.length; i++) {
						if (i > 1) {
							info += "|"
						}
						info += myArray[i]
					}
					return info
				} else {
					return name;
				}
			}
			return "";
		},
		getAgglevel (name, val) {
			if (name != null && name.length > 0) {
				var myArray = name.split("|");
				if (myArray.length > 1) {
					for (var i = 0; i < myArray.length; i++) {
						var info = myArray[i].split(" (")[0]
						var code = myArray[i].split("(")[1].split(")")[0]
						var value = myArray[i].split(" [")[1].split("]")[0] + 0
						if (code == val) {
							return info
						}
					}
				}
				return name;
			} else {
				return "";
			}
		},
		itemDelete(item) {
			this.deleteItemDialog = true;
			this.deleteItem = item
		},
		itemsDelete() {
			this.deleteItemsDialog = true;
		},
		itemAdd(item) {
			this.addItemDialog = true;
			this.submitted = false;
			this.addItem = item
			this.addItem.ordername = null;
		},
		itemsAdd() {
			this.addItemsDialog = true;
			this.submitted = false;
			this.ordername = null;
		},
		basketDelete(productid) {
			this.basketService.getBasketDelete(productid).then(data => {
				if (data.code == 200) {
					this.basket = data.result;
					this.itemsBadgeReload(this.items, 0);
					this.deleteItemDialog = false;
					this.deleteItem = null;
				}
			});

		},
		basketAllDelete() {
			this.basketService.getBasketAllDelete().then(data => {
				if (data.code == 200) {
					this.basket = data.result;
					this.itemsBadgeReload(this.items, 0);
					this.deleteItemsDialog = false;
				}
			});			
		},
		orderAdd() {
			this.submitted = true;
			if (this.addItem.ordername && this.addItem.ordername.trim()) {
				if (this.addItem.conceptid) {
					this.basketService.getOrderAdd(this.addItem).then(data => {
						if (data.code == 200) {
							this.basket = data.result;
							this.itemsBadgeReload(this.items, 0);
							this.addItemDialog = false;
							this.addItem = null;
						}
					});
				}
			}
		},
		orderAllAdd() {
			this.submitted = true;
			if (this.ordername && this.ordername.trim()) {
				this.basketService.getOrderAllAdd(this.ordername).then(data => {
					if (data.code == 200) {
						this.basket = data.result;
						this.itemsBadgeReload(this.items, 0);
						this.addItemsDialog = false;
						this.ordename = null;
					}
				});
			}
		},
		getLabel(label) {
			return this.$t("message."+label, {}, { locale: this.$appState.locale });
		},
		getSearchLabel() {
			return this.$t("message.menu.Search", {}, { locale: this.$appState.locale });
		},
		itemsBadgeReload(items, level) {
			if (items && Array.isArray(items) && level < 100) {
				items.forEach( item => {
					if (item.items) {
						this.itemsBadgeReload (item.items, level+1)
					}
					if (item.type) {
						if (item.type == 'shopping') {
							this.basketService.getBasketNum().then (res => {
								item.badge = res;
							});
						} else {
							if (item.type == 'order' ) {
								this.basketService.getOrderNum().then (res => {
									item.badge = res;
								});						
							}
						}							
					}
				})
			}
		},
	}
}

</script>

<style scoped>

</style>
